import React, { useEffect, useState } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const email = localStorage.getItem('userEmail');

  const handleSearch = async () => {
    const db = getFirestore();
    const usersCollection = collection(db, 'users');
    const q = query(usersCollection, where('email', '==', email));

    try {
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        setUserData(userDoc.data());
        setError(null);
      } else {
        setUserData(null);
        setError('No user found with this email.');
      }
    } catch (err) {
      console.error('Error fetching user data:', err);
      setError('An error occurred while fetching user data.');
    }
  };

  useEffect(() => {
    handleSearch();
  }, [email]);

  const calculateCompletion = () => {
    if (!userData) return 0;

    const totalFields = 6; // Total number of fields to check
    let filledFields = 0;

    // Check which fields are filled
    if (userData.name) filledFields++;
    if (userData.email) filledFields++;
    if (userData.phone) filledFields++;
    if (userData.dob) filledFields++;
    if (userData.dlNo) filledFields++;
    if (userData.aadhar) filledFields++;

    return (filledFields / totalFields) * 100; // Return percentage
  };

  const completionPercentage = calculateCompletion();

  return (
    <div className="flex flex-col h-screen bg-gradient-to-br from-green-300 to-blue-400 p-10">
      {error && <p className="text-red-500 text-center">{error}</p>}

      {userData && (
        <div className="flex flex-col flex-grow bg-white shadow-xl rounded-lg p-8">
          {/* Profile Completion Section */}
          <div className="mt-6">
            <p className="text-center">Profile Completion: {completionPercentage.toFixed(0)}%</p>
            <div className="w-full bg-gray-200 rounded-full h-4 mt-2">
              <div
                className="bg-green-500 h-full rounded-full"
                style={{ width: `${completionPercentage}%` }}
              />
            </div>
          </div>

          <h2 className="text-2xl font-semibold mb-4 text-center text-gray-800">User Profile</h2>
          <div className="space-y-2">
            <p><strong>Name:</strong> {userData.name}</p>
            <p><strong>Email:</strong> {userData.email}</p>
            <p><strong>Phone:</strong> {userData.phone}</p>
            <p><strong>DOB:</strong> {userData.dob}</p>
            <p><strong>DL No:</strong> {userData.dlNo}</p>
            <p><strong>Aadhar:</strong> {userData.aadhar}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
