
import React, { useEffect, useState } from 'react';

export default function Trips() {
    const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [pastBookings, setPastBookings] = useState([]);
  const [activeTab, setActiveTab] = useState('upcoming');
  return (
    <div className="mt-8">
            <div className="flex justify-center">
              <button
                className={`px-4 py-2 font-semibold ${activeTab === 'upcoming' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'} rounded-l`}
                onClick={() => setActiveTab('upcoming')}
              >
                Upcoming Bookings
              </button>
              <button
                className={`px-4 py-2 font-semibold ${activeTab === 'past' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'} rounded-r`}
                onClick={() => setActiveTab('past')}
              >
                Past Bookings
              </button>
              <button
                className={`px-4 py-2 font-semibold ${activeTab === 'inBooking' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'} rounded-r`}
                onClick={() => setActiveTab('inBooking')}
              >
                In Booking
              </button>
              <button
                className={`px-4 py-2 font-semibold ${activeTab === 'cancel' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'} rounded-r`}
                onClick={() => setActiveTab('cancel')}
              >
                Cancelled Booking
              </button>
            </div>

            {/* Booking List */}
            <div className="mt-4">
              {activeTab === 'upcoming' && upcomingBookings.length > 0 ? (
                upcomingBookings.map((booking, index) => (
                  <div key={index} className="border-b py-2">
                    <p><strong>Date:</strong> {new Date(booking.date).toLocaleDateString()}</p>
                    <p><strong>Car:</strong> {booking.carModel}</p>
                    <p><strong>Status:</strong> {booking.status}</p>
                  </div>
                ))
              ) : activeTab === 'upcoming' ? (
                <p className="text-center">No upcoming bookings.</p>
              ) : pastBookings.length > 0 ? (
                pastBookings.map((booking, index) => (
                  <div key={index} className="border-b py-2">
                    <p><strong>Date:</strong> {new Date(booking.date).toLocaleDateString()}</p>
                    <p><strong>Car:</strong> {booking.carModel}</p>
                    <p><strong>Status:</strong> {booking.status}</p>
                  </div>
                ))
              ) : (
                <p className="text-center">No past bookings.</p>
              )}
            </div>
          </div>
  )
}

