import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { GiCarSeat, GiGearStickPattern } from 'react-icons/gi';
import { BsFuelPump } from 'react-icons/bs';
import { getFirestore, collection, getDocs, query, where, addDoc } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default function CarList() {
  const [cars, setCars] = useState([]);
  const [availableCars, setAvailableCars] = useState([]);
  const [bookedCarIds, setBookedCarIds] = useState([]);
  const [user, setUser] = useState(null);
  const location = useLocation();
  const { cartype, selectcity, pickupdate, dropdate, pickTime, dropTime } = location.state || {};
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    const fetchCars = async () => {
      const db = getFirestore();
      const carCollection = collection(db, 'cars');
      const carSnapshot = await getDocs(carCollection);
      const carData = carSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCars(carData);
      console.log("Fetched cars:", carData); // Debugging log
    };

    fetchCars();
  }, []);

  useEffect(() => {
    const fetchAvailableCars = async () => {
      if (!pickupdate || !dropdate || !pickTime || !dropTime) return;
      localStorage.setItem('pickupdate',pickupdate);
      localStorage.setItem('dropdate',dropdate);
      localStorage.setItem('pickuptime',pickTime);
      localStorage.setItem('droptime',dropTime);
      const db = getFirestore();
      const bookingCollection = collection(db, 'carBookings');
      const startDate = new Date(`${pickupdate}T${pickTime}`).toISOString();
      const endDate = new Date(`${dropdate}T${dropTime}`).toISOString();

      console.log(`Querying bookings between ${startDate} and ${endDate}`); // Debugging log

      const q = query(
        bookingCollection,
        where('pickupDate', '<=', endDate),
        where('dropoffDate', '>=', startDate)
      );

      const bookingSnapshot = await getDocs(q);
      const bookedCars = bookingSnapshot.docs.map(doc => doc.data().carId);

      console.log("Booked Cars:", bookedCars); // Debugging log
      setBookedCarIds(bookedCars);

      const availableCars = cars.filter(car => !bookedCars.includes(car.id));
      console.log("Available Cars:", availableCars); // Debugging log
      setAvailableCars(availableCars);
    };

    fetchAvailableCars();
  }, [pickupdate, dropdate, pickTime, dropTime, cars]);

  // Check if the user is logged in
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  const handleBookNow = async (carId) => {
    if (!user) {
      alert("Please log in first to book a car.");
      navigate('/loginsignup')
      return;
    }

    try {
      const car = cars.find(car => car.id === carId);
      if (!car) {
        console.error("Car not found:", carId);
        alert("Car not found. Please try again.");
        return;
      }

      navigate('/payment', { state: { car, pickupdate, dropdate, pickTime, dropTime } });
      const db = getFirestore();
      const bookingCollection = collection(db, 'carBookings');

      const newBooking = {
        carId: carId,
        carName: car.carName,
        pickupDate: pickupdate,
        pickupTime: pickTime,
        dropoffDate: dropdate,
        dropoffTime: dropTime,
        userId: user.uid,
        name : localStorage.getItem('userName'),// Add user name to the booking
        phone : localStorage.getItem('phoneNumber')
      };

      //await addDoc(bookingCollection, newBooking);

      //alert("Car booked successfully!");
      //alert("Website Under Construction, For Booking Contact 9777824577");

      // Refresh available cars
      const fetchAvailableCars = async () => {
        if (!pickupdate || !dropdate || !pickTime || !dropTime) return;

        const db = getFirestore();
        const bookingCollection = collection(db, 'carBookings');
        const startDate = new Date(`${pickupdate}T${pickTime}`).toISOString();
        const endDate = new Date(`${dropdate}T${dropTime}`).toISOString();

        console.log(`Querying bookings between ${startDate} and ${endDate}`); // Debugging log

        const q = query(
          bookingCollection,
          where('pickupDate', '<=', endDate),
          where('dropoffDate', '>=', startDate)
        );

        const bookingSnapshot = await getDocs(q);
        const bookedCars = bookingSnapshot.docs.map(doc => doc.data().carId);

        console.log("Booked Cars:", bookedCars); // Debugging log
        setBookedCarIds(bookedCars);

        const availableCars = cars.filter(car => !bookedCars.includes(car.id));
        console.log("Available Cars:", availableCars); // Debugging log
        setAvailableCars(availableCars);
      };

      fetchAvailableCars();

    } catch (error) {
      //console.error("Error booking car:", error);
      alert("Failed to book car. Please try again.");
    }
  };

  return (
    <div>
      <div className='flex flex-wrap space-x-4 space-y-4 justify-center gap-7 mt-10'>
        {cars.map(car => (
          <motion.div 
            key={car.id}
            whileTap={{ scale: 0.9 }} 
            className='group w-80 min-w-[94px] h-max cursor-pointer rounded-lg drop-shadow-lg 
              flex flex-col items-center justify-center bg-slate-400 hover:bg-green-500 '
          >
            <p className='text-lg font-semibold mt-3'>{car.carName}</p>
            <img src={car.carPhoto} className='w-41 h-40' alt='car'/>
            
            <div className='grid grid-cols-3 w-full mt-1'>
              <div className='flex flex-row items-center m-auto'>
                <GiCarSeat size={20} className='text-black' />
                <p className='text-xs m-1 font-semibold'>{car.seatCapacity}</p>
              </div>
              <div className='flex flex-row items-center m-auto'>
                <BsFuelPump size={20} className='text-black' />
                <p className='text-xs m-1 font-semibold'>{car.fuelType}</p>
              </div>
              <div className='flex flex-row items-center m-auto'>
                <GiGearStickPattern size={20} className='text-black' />
                <p className='text-xs m-1 font-semibold'>{car.transmissionType}</p>
              </div>
            </div>
            <p className='m-4 font-semibold'>₹ {car.price} /-</p>
            <p className='m-4 text-center'>Unlimited KMS without Fuel. Free Airport Delivery.</p>
            {bookedCarIds.includes(car.id) ? (
              <button 
                className='bg-red-700 p-2 border rounded-md m-2 text-slate-200 hover:shadow-slate-500'
                disabled
              >
                Car Booked
              </button>
            ) : (
              <button 
                className='bg-green-700 p-2 border rounded-md m-2 hover:bg-green-500 text-slate-200'
                onClick={() => handleBookNow(car.id)}
              >
                Book Now
              </button>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
}