import React from 'react';
import { useLocation } from 'react-router-dom';

const Success = () => {
    const query = new URLSearchParams(useLocation().search);

    const txnId = query.get('txn_id');
    const amount = query.get('amount');
    const date = query.get('date');
    const phone = query.get('phone');
    const email = query.get('email');
    const userid = query.get('userid');
    const carname = query.get('carname');
    const pickupDate = query.get('pickupDate');
    const dropDate = query.get('dropDate');

    if (!txnId) {
        return <div>Error: Payment details are missing</div>;
    }

    return (
        <div>
            <h1>Payment Success</h1>
            <p>Transaction ID: {txnId}</p>
            <p>Amount: ₹{amount}</p>
            <p>Date: {date}</p>
            <p>Phone: {phone || 'N/A'}</p>
            <p>Email: {email || 'N/A'}</p>
            <p>User ID: {userid || 'N/A'}</p>
            <p>Car Name: {carname || 'N/A'}</p>
            <p>Pickup Date: {pickupDate || 'N/A'}</p>
            <p>Drop Date: {dropDate || 'N/A'}</p>
        </div>
    );
};

export default Success;
