import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Maincomp from './components/Maincomp';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import Terms from './components/Terms';
import CarList from './components/CarList';
import AddCar from './components/AddCar';
import BlockCar from './components/BlockCar';
import Profile from './components/Profile';
import UpcomingBookings from './components/UpcomingBookings';
import PaymentPage from './components/PaymentPage';
import AdminDashboard from './components/AdminDashboard';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Loading from './components/Loading';
import './index.css';
import CancellationPolicyPage from './components/CancellationPolicyPage';
import Removecar from './components/Removecar';
import Insta from './components/InstaMojo';
import Success from './components/Success'
import Coupon from './components/Coupon';
import Privacy from './components/Privacy';
import Blog from './components/Blog';
import UserProfile from './components/UserProfile';
import Trips from './components/Trips'
import SuccessPage from './components/SuccessPage';

function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleRouteChange = () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000); // Simulate loading time
    };

    handleRouteChange();

    return () => {
      handleRouteChange();
    };
  }, [location]);

  return (
    <div>
      <Navbar />
      {loading ? (
        <Loading />
      ) : (
        <Routes>
          <Route path="/" element={<Maincomp />} />
          <Route path="/loginsignup" element={<Login />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/pay" element={<Terms />} />
          <Route path="/addcar" element={<AddCar />} />
          <Route path="/result" element={<CarList />} />
          <Route path="/block" element={<BlockCar />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/upcomingbookings" element={<UpcomingBookings />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/policy" element={<CancellationPolicyPage />} />
          <Route path="/remove" element={<Removecar />} />
          <Route path="/paymentnew" element={<Insta />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path='/addCoupon' element={<Coupon />}/>
          <Route path='/privacy' element={<Privacy />}/>
          <Route path='/blog' element={<Blog/>}/>
          <Route path='/user' element={<UserProfile />} />
          <Route path='/trip' element={<Trips />} />
          
        </Routes>
      )}
      <Footer />
    </div>
  );
}

export default App;
